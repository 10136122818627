import {
  Grid,
  Paper,
  PaperGroupItem,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { ConfirmationSoftLaunchContainer } from '../../modules/Confirmation/ConfirmationSoftLaunch'
import { IncrementalFieldworkContainer } from '../../modules/Confirmation/IncrementalFieldwork'
import { getDaysSuffix } from '../../utils/HelperFunctions'
import dayjs from '../../utils/dayjs'
import useStyles from './styles'

interface Props {
  estimatedTimeToComplete?: number
  completionDate: string
  displayedDate: Date
}

const ConfirmationStartDate: React.FC<Props> = (props: Props) => {
  const { estimatedTimeToComplete, completionDate, displayedDate } = props
  const { classes } = useStyles()

  return (
    <Paper>
      <PaperGroupItem>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              1
            </Text>
          </Grid>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              Start date
            </Text>
          </Grid>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column" rowSpacing={2}>
          <Grid container item direction="row">
            <Text highlight={TextHighlight.Background} size={TextSize.ms}>
              Go live date:
            </Text>
            <Text
              size={TextSize.ms}
              weight={TextWeight.SemiBold}
              className={classes.estimateLengthStyle}
            >
              {dayjs(displayedDate).format('D MMM YYYY')}
            </Text>
          </Grid>
          <Grid container item direction="row">
            <Grid container item direction="row" xs={5}>
              <Text highlight={TextHighlight.Background} size={TextSize.ms}>
                Estimated time to complete:
              </Text>
              <Text
                size={TextSize.ms}
                weight={TextWeight.SemiBold}
                className={classes.estimateLengthStyle}
              >
                {estimatedTimeToComplete || '...'}{' '}
                {estimatedTimeToComplete
                  ? getDaysSuffix(estimatedTimeToComplete)
                  : 'days'}
              </Text>
            </Grid>
            <Grid container item direction="row" xs={7}>
              <Text highlight={TextHighlight.Background} size={TextSize.ms}>
                Completion date:
              </Text>
              <Text
                size={TextSize.ms}
                weight={TextWeight.SemiBold}
                className={classes.estimateLengthStyle}
              >
                {estimatedTimeToComplete && completionDate}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column">
          <ConfirmationSoftLaunchContainer />
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column">
          <IncrementalFieldworkContainer />
        </Grid>
      </PaperGroupItem>
    </Paper>
  )
}

export default ConfirmationStartDate
