import {
  Button,
  ButtonVariant,
  Grid,
  IconName,
  KebabMenu,
  KebabMenuOption,
  Menu,
  MenuOption,
  MenuVariant,
  Switch,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { useGetSoftLaunchOrder } from '../../components/SoftLaunch'
import { SurveyState } from '../../data/gql-gen/dashboard/graphql'
import { SurveyParams } from '../../data/model/surveyParams'
import InviteTeamDialog from '../../modules/Project/InviteTeamDialog/InviteTeamDialog.container'
import { FullLaunchButtonContainer } from '../../modules/Results/FullLaunchButton'
import useStyles from './styles'

enum KebabMenuOptionId {
  Preview,
  Export,
  Duplicate,
  PauseUnpause,
  End
}

interface Props {
  disableShowQuotas: boolean
  surveyState: SurveyState | undefined
  onClickPause: () => void
  onClickEnd: () => void
  onClickDuplicate: () => void
  onClickChartData: () => void
  onClickRawData: () => void
  onClickDataTables: () => void
  onClickFollowUp: () => void
  onClickPreview: () => void
  onClickExportSurvey: () => void
  onClickExportToPptx: () => void
  onShowQuotasChange: (showQuotas: boolean) => void
  isMultiMarket?: boolean
}

const ResultsOptions: React.FC<Props> = (props: Props) => {
  const {
    disableShowQuotas,
    surveyState,
    onClickPause,
    onClickEnd,
    onClickDuplicate,
    onClickFollowUp,
    onClickChartData,
    onClickRawData,
    onClickDataTables,
    onClickPreview,
    onClickExportSurvey,
    onClickExportToPptx,
    onShowQuotasChange,
    isMultiMarket = false
  } = props
  const { classes } = useStyles()
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const { isSoftLaunchOrder, isSoftLaunchInProgress, isSoftLaunchCompleted } =
    useGetSoftLaunchOrder()

  const canFullLaunch =
    isSoftLaunchOrder &&
    (isSoftLaunchInProgress || isSoftLaunchCompleted) &&
    surveyState === SurveyState.Live

  const disablePauseUnpauseProject = isSoftLaunchOrder && isSoftLaunchCompleted

  const onChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    onShowQuotasChange(event.target.checked)
    if (event.target.checked) {
      LogAmplitudeEvent(EventType.ToggledResultsQuotas, { surveyId })
    }
  }

  const getMainText: () => string = () => {
    switch (surveyState) {
      case SurveyState.Paused:
        return 'Unpause project'
      default:
        return 'Pause project'
    }
  }

  const getIconName: () => IconName = () => {
    switch (surveyState) {
      case SurveyState.Paused:
        return IconName.PlayCircleOutline
      default:
        return IconName.PauseCircleOutline
    }
  }

  const liveOnlyKebabMenuOptions: KebabMenuOption[] = [
    {
      id: KebabMenuOptionId.PauseUnpause,
      textItem: getMainText(),
      disabled: disablePauseUnpauseProject,
      onClickItem: () => onClickPause(),
      iconName: getIconName()
    },
    {
      id: KebabMenuOptionId.End,
      textItem: 'End project',
      onClickItem: () => onClickEnd(),
      iconName: IconName.FlagOutlined
    }
  ]
  const commonKebabMenuOptions: KebabMenuOption[] = [
    {
      id: KebabMenuOptionId.Preview,
      textItem: 'Preview survey',
      onClickItem: () => onClickPreview(),
      iconName: IconName.Preview
    },
    {
      id: KebabMenuOptionId.Export,
      textItem: 'Export survey (.doc)',
      onClickItem: onClickExportSurvey,
      iconName: IconName.Export
    },
    {
      id: KebabMenuOptionId.Duplicate,
      textItem: 'Duplicate and edit',
      onClickItem: () => onClickDuplicate(),
      iconName: IconName.Duplicate
    }
  ]

  const getKebabMenuOptions: (
    surveyState: SurveyState | undefined
  ) => KebabMenuOption[] = (surveyState) => {
    // On a multi-market survey the menu should be empty
    if (isMultiMarket) {
      return []
    }

    switch (surveyState) {
      case SurveyState.Scheduled:
        return [...commonKebabMenuOptions]
      case SurveyState.Completed:
      case SurveyState.Halted:
        return [...commonKebabMenuOptions]
      default:
        return [...liveOnlyKebabMenuOptions, ...commonKebabMenuOptions]
    }
  }

  const getFollowUpButton: (
    surveyState: SurveyState | undefined
  ) => JSX.Element | null = (surveyState) => {
    switch (surveyState) {
      case SurveyState.Completed:
        return (
          <Button
            variant={ButtonVariant.SecondaryNoOutline}
            onClick={onClickFollowUp}
          >
            Follow up
          </Button>
        )
      default:
        return null
    }
  }

  const kebabMenuOptions = getKebabMenuOptions(surveyState)

  const options = [
    {
      text: 'Download chart data',
      onClick: onClickChartData
    },
    {
      text: 'Download raw data file',
      onClick: onClickRawData
    },
    {
      text: 'Request data tables',
      onClick: onClickDataTables,
      disabled: isMultiMarket
    },
    {
      text: 'Export as presentation',
      onClick: onClickExportToPptx
    }
  ]

  const requestDataMenuOptions: MenuOption[] = [
    {
      id: 0,
      options
    }
  ]

  const followUpButton = getFollowUpButton(surveyState)

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs="auto">
        <Grid container direction="row" alignItems="flex-end">
          <Grid item xs="auto" className={classes.resultsText}>
            <Text
              weight={TextWeight.SemiBold}
              highlight={TextHighlight.Standard}
              size={TextSize.lxl}
              marginRight
            >
              Results
            </Text>
          </Grid>
          {!isMultiMarket && (
            <>
              <Grid item xs="auto">
                <Text
                  weight={TextWeight.Regular}
                  highlight={TextHighlight.Background}
                  size={TextSize.m}
                >
                  Show quotas
                </Text>
              </Grid>
              <Switch first onChange={onChange} disabled={disableShowQuotas} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs="auto"
        className={
          !isMultiMarket ? classes.rightContainer3 : classes.rightContainer1
        }
      >
        <Grid container direction="row" alignItems="center">
          <InviteTeamDialog />
          {!isMultiMarket && followUpButton}
          <Grid item xs="auto" className={classes.requestData}>
            <Menu
              variant={MenuVariant.Body}
              menuOptions={requestDataMenuOptions}
              title="Export data"
            />
          </Grid>
          {canFullLaunch && <FullLaunchButtonContainer />}
          <Grid
            item
            xs="auto"
            className={classes.kebabMenu}
            data-testid="result-options-kebab"
          >
            <KebabMenu kebabMenuOptions={kebabMenuOptions} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResultsOptions
