import { useMutation, useQuery } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { SnackbarVariant, useSnackbar } from '@focaldata/cin-ui-components'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { getDefaultCompletes } from '../../../controls/ProjectTypesPanel/ProjectTypesPanel.utils'
import {
  CreateSurveyOrderV2MutationVariables,
  ProjectType
} from '../../../data/gql-gen/dashboard/graphql'
import {
  AdHocTestingSpecification,
  UpdateFieldworkV2Input
} from '../../../data/gql-gen/questionnaire/graphql'
import {
  CREATE_SURVEY_ORDER_V2,
  CreateSurveyOrderData
} from '../../../data/gql/order/mutations/createSurveyOrder'
import { UPDATE_FD_CHAT_SPECIFICATION_V2 } from '../../../data/gql/questionnaire/mutations/updateFDChatSpecification'
import { LoggerErrorType } from '../../../data/logger'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { useFieldworkData } from '../../../modules/Audience/Audience.hooks'
import { useProjectData } from '../../../modules/Project/Project.hooks'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { downloadFile } from '../../../utils/projectUtils'
import { convertAudienceType } from './AudiencePage/FdChatAudience'
import { ContextType } from './FdChat.container'
import { ResultsType, TranscriptsFileFormatType } from './FdChat.model'
import {
  FD_CHAT_SPECIFICATION_V2,
  draftFDChatSpecificationRefetchQuery
} from './FdChat.query'
import { generateTranscriptsFilename } from './FdChat.utils'

export const useGetFdChatSpecification = () => {
  const surveyId = useSurveyId()
  const projectId = useProjectId()
  const { data, refetch, loading, error } = useQuery(FD_CHAT_SPECIFICATION_V2, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'cache-first',
    variables: {
      surveyId,
      projectId
    }
  })

  return {
    fdChatSpecification: data?.draftQuestionnaireV2?.fdChatSpecification,
    refetchFdChatSpecification: refetch,
    loadingFdChatSpecification: loading,
    error
  }
}

export const useAddFdChatSpecification = () => {
  const [addFdChatSpecification] = useMutation(
    UPDATE_FD_CHAT_SPECIFICATION_V2,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFdChatSpecificationV2',
          error
        )
      }
    }
  )

  return { addFdChatSpecification }
}

export const usePlaceOrder = () => {
  const surveyId = useSurveyId()
  const { total, costPerInterview } = useFdChatPrice()
  const { fieldworkData } = useFieldworkData()
  const { updateFieldworkV2, updateFieldworkLoading } = useUpdateFieldworkV2()

  const [createSurveyOrderV2, { loading: placingOrderLoadingV2 }] = useMutation<
    CreateSurveyOrderData,
    CreateSurveyOrderV2MutationVariables
  >(CREATE_SURVEY_ORDER_V2, {
    context: { clientName: 'dashboard' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createSurveyOrder',
        error
      )
    }
  })

  const placeOrder = async () => {
    if (!fieldworkData) return

    const startDate = dayjs().toISOString()
    const endDate = dayjs().add(7, 'days').toISOString()
    const panelSupplierCode = convertAudienceType(
      fieldworkData.audience[0].__typename
    )

    await updateFieldworkV2({
      variables: {
        input: {
          surveyId,
          panelSupplierCode,
          startDate,
          endDate
        }
      }
    })

    await createSurveyOrderV2({
      variables: {
        surveyId,
        panelSupplierCode: panelSupplierCode,
        customerCPI: costPerInterview,
        baseCPI: total,
        feasibility: 1.0
      }
    })
  }

  return {
    totalCost: total,
    placingOrderLoading: updateFieldworkLoading || placingOrderLoadingV2,
    placeOrder
  }
}

export const useFdChatResults = () => {
  const surveyId = useSurveyId()
  const [results, setResults] = useState<ResultsType>()
  const [loading, setLoading] = useState(true)

  const fetchResults = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FD_CHAT_ANALYSIS_BUCKET_URL}/${surveyId}.json`
      )
      const json = await response.json()
      setLoading(false)
      setResults(json)
    } catch (error) {
      setLoading(false)
      setResults(undefined)
    }
  }, [surveyId])

  useEffect(() => {
    fetchResults()
  }, [fetchResults])

  return {
    results,
    loading,
    hasFindings: !!(results?.research_goal && results.research_questions),
    refetch: fetchResults
  }
}

export const useFdChatValidation = () => {
  const { fieldworkData } = useFieldworkData()
  const { fdChatSpecification } = useGetFdChatSpecification()
  const { draftAudience } = useGetDraftAudience()

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: draftAudience?.validationErrors
  })
  const { hasValidationErrors } = useQuestionnaireValidation()

  const audienceValid =
    !!fieldworkData?.samplesNeeded.unadjusted &&
    !!fieldworkData.lengthOfInterview.unadjusted &&
    !isAudienceInvalid

  if (!fdChatSpecification)
    return {
      objectivesValid: false,
      audienceValid
    }

  const objectivesValid =
    fdChatSpecification.__typename === 'AdHocTestingSpecification' &&
    fdChatSpecification.goal.length > 0 &&
    fdChatSpecification.questions[0]?.length > 0

  return {
    objectivesValid,
    audienceValid,
    questionnaireValid: !hasValidationErrors()
  }
}

export const useUpdateFdChatFieldwork = () => {
  const surveyId = useSurveyId()
  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const { fieldworkData } = useFieldworkData()

  const updateFdChatFieldwork = useCallback(
    (variables: Partial<UpdateFieldworkV2Input>) => {
      if (!fieldworkData) return
      const audience = fieldworkData.audience[0]
      updateFieldworkV2({
        variables: {
          input: {
            surveyId,
            panelSupplierCode: convertAudienceType(audience.__typename),
            countryCode: fieldworkData.locale.country,
            countryLanguage: fieldworkData.locale.language,
            audienceDescription:
              audience.__typename !== 'CintAudience'
                ? audience.description
                : undefined,
            ...variables
          }
        },
        refetchQueries: ['fieldworkV2']
      })
    },
    [fieldworkData, surveyId, updateFieldworkV2]
  )

  const debouncedUpdateFdChatFieldwork = useMemo(
    () =>
      debounce((variables: Partial<UpdateFieldworkV2Input>) => {
        updateFdChatFieldwork(variables)
      }, 1000),
    [updateFdChatFieldwork]
  )

  return { updateFdChatFieldwork, debouncedUpdateFdChatFieldwork }
}

export const useFdChatPrice = () => {
  const { fieldwork, isCustomAudience } = useFdChatFieldwork()

  const numberOfInterviews =
    fieldwork?.samplesNeeded.unadjusted ??
    getDefaultCompletes(ProjectType.FdChat)

  const respondentFee = isCustomAudience ? 0 : 15
  const dataProcessingPerInterview = isCustomAudience ? 3 : 1

  const costPerInterview = respondentFee + dataProcessingPerInterview
  const total = costPerInterview * numberOfInterviews

  return {
    respondentFee,
    dataProcessingPerInterview,
    total,
    costPerInterview
  }
}

export const fdChatSpecificationDebounceDelay = 1000
export const useUpdateFdChatSpecification = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const { addFdChatSpecification } = useAddFdChatSpecification()

  const updateFdChatSpecification = useCallback(
    async (newSpecification: AdHocTestingSpecification) => {
      await addFdChatSpecification({
        variables: {
          input: {
            surveyId,
            projectId,
            fdChatSpecification: { value: { ...newSpecification } }
          }
        },
        refetchQueries: [FD_CHAT_SPECIFICATION_V2]
      })
    },
    [addFdChatSpecification, surveyId, projectId]
  )

  const debouncedUpdateFdChatSpecification = useMemo(
    () =>
      debounce(async (newSpecification) => {
        await updateFdChatSpecification(newSpecification)
      }, fdChatSpecificationDebounceDelay),
    [updateFdChatSpecification]
  )

  const updateFdChatSpecificationCacheAndDebounce = (
    newSpecification: AdHocTestingSpecification
  ) => {
    draftFDChatSpecificationRefetchQuery(projectId, surveyId)
    debouncedUpdateFdChatSpecification(newSpecification)
  }

  return {
    updateFdChatSpecification,
    updateFdChatSpecificationCacheAndDebounce
  }
}

export const useFdChatFieldwork = () => {
  const { fieldworkData, loading: fieldworkLoading } = useFieldworkData()

  const isCustomAudience =
    fieldworkData?.audience[0].__typename === 'CustomAudience'

  return {
    fieldwork: fieldworkData,
    fieldworkLoading,
    isCustomAudience
  }
}

const FD_CHAT_ANALYTICS_URL = `${
  process.env.REACT_APP_URL_FD_CHAT_ANALYTICS ||
  process.env.REACT_APP_REST_DOMAIN
}/v1/analytics`

export const useExportFdChatTranscripts = () => {
  const surveyId = useSurveyId()
  const { project } = useProjectData()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const exportTranscripts = async ({
    fileFormat
  }: {
    fileFormat: TranscriptsFileFormatType
  }) => {
    setLoading(true)

    try {
      const url = `${FD_CHAT_ANALYTICS_URL}/transcripts/${surveyId}?project_name=${project?.name}&file_format=${fileFormat}`
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error('Error from fdchat analytics service')
      }

      const data = await response.blob()
      if (data.size <= 1) {
        throw new Error('No data in fdchat transcript file')
      }

      const dataUrl = window.URL.createObjectURL(data)
      const fileName = generateTranscriptsFilename(
        project?.name || surveyId,
        fileFormat
      )

      downloadFile({ dataUrl, fileName })
      window.URL.revokeObjectURL(dataUrl)

      enqueueSnackbar(
        'Downloading transcripts... Please check your downloads folder shortly.',
        {
          variant: SnackbarVariant.Info
        }
      )
    } catch (error: any) {
      datadogLogs.logger.error(
        `[FDChat Export Transcripts] ${error.message}`,
        {},
        error
      )
      enqueueSnackbar('Something went wrong. Please try again later.', {
        variant: SnackbarVariant.Error
      })
    }

    setLoading(false)
  }

  return { exportTranscripts, loading }
}

export const useFdChatContainerContent = () => {
  return useOutletContext<ContextType>()
}

export const useInterviewLink = ({
  preview = false
}: {
  preview?: boolean
} = {}) => {
  const surveyId = useSurveyId()

  return `${
    process.env.REACT_APP_RESPONDENT_DOMAIN
  }?sid=${surveyId}&ps=fd&type=fdchat&preview=${preview}&collect=${!preview}`
}
