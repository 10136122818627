import { Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'
import { LogPublicAmplitudeEvent } from '../../amplitude/public'
import CreateProject from '../../modules/Project/CreateProject'
import {
  FocaldataPreferredApp,
  isFocaldataUser,
  projectSetupHost
} from '../../utils/authUtils'
import ProjectTypeCard from '../ProjectTypeItem'
import styles from './ProjectTypesPanel.module.css'
import { getAvailableProjectTypes } from './ProjectTypesPanel.utils'

export const ProjectTypesPanel = () => {
  const projectTypes = getAvailableProjectTypes()

  return (
    <div className="project-types-panel">
      <Text size={TextSize.l} weight={TextWeight.Bold}>
        Create a new project
      </Text>
      <div className={styles['project-types-wrapper']}>
        {projectTypes.map((projectType) => (
          <ProjectTypeCard
            {...projectType}
            key={projectType.type}
            action={
              <CreateProject
                dialogTitle="Name new project"
                type={projectType.type}
                hideCountry
                hideCompletes
                defaultCompletes={projectType.defaultCompletes}
              />
            }
          />
        ))}
        {isFocaldataUser() && (
          <ProjectTypeCard
            title="Focaldata AI"
            description="Get in-depth insights at scale using AI-moderated interviews."
            action={
              <a
                className={styles.link}
                href={projectSetupHost}
                onClick={(e) => {
                  e.preventDefault()
                  LogPublicAmplitudeEvent('Clicked on Go to Focaldata AI')
                  FocaldataPreferredApp.set('coworker')
                  window.location.href = projectSetupHost
                }}
              >
                Go to Focaldata AI
              </a>
            }
          />
        )}
      </div>
    </div>
  )
}
