import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'
import { datadogLogs } from '@datadog/browser-logs'
import { ThemeProvider } from '@focaldata/cin-ui-components'
import { StyledEngineProvider } from '@mui/material/styles'
import React, { ReactNode, useEffect } from 'react'
import { Provider } from 'react-redux'
import { NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom'
import { store } from './App.store'
import client from './apollo/apolloClient'
import StagingInfoBanner from './components/StagingInfoBanner/StagingInfoBanner'
import CreatePassword from './containers/Auth/CreatePassword'
import ForgotPasswordComponent from './containers/Auth/ForgotPassword'
import ForgotPasswordSuccessful from './containers/Auth/ForgotPassword/ForgotPasswordSuccessful'
import ResetPasswordContainer from './containers/Auth/ForgotPassword/ResetPassword'
import LoginComponent from './containers/Auth/Login'
import SignupComponent from './containers/Auth/Signup'
import SignupSuccessful from './containers/Auth/SignupSuccessful'
import Page from './containers/Page'
import TermsAndConditions from './controls/TermsAndConditions'
import { Refresh } from './data/rest/accountUser'
import { DATADOG_VERSION } from './tracking/datadog'
import { getEnvironment } from './utils/HelperFunctions'
import { AccessToken, getLoginRedirectPath } from './utils/authUtils'

const isProd = window.location.hostname.endsWith('.com')
const preProdBranch = process.env.REACT_APP_PRE_PROD_BRANCH
const latestReleaseVersion = process.env.REACT_APP_RELEASED_VERSION

type Props = {
  children: ReactNode
}
const refreshTokens = async (navigate: NavigateFunction) => {
  try {
    const response = await Refresh()
    AccessToken.set(response.data.accessToken)
  } catch (err) {
    // The 'refreshToken' is invalid or the account-service is down so route the user to login page
    navigate(getLoginRedirectPath())
  }
}

const RefreshTokenWrapper: React.FC<{ children: ReactNode }> = ({
  children
}: Props) => {
  const navigate = useNavigate()
  const TOKEN_REFRESH_INTERVAL = 15 * 60 * 1000

  useEffect(() => {
    refreshTokens(navigate)

    setInterval(() => {
      refreshTokens(navigate)
    }, TOKEN_REFRESH_INTERVAL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

const initDatadogLogs = () => {
  datadogLogs.init({
    clientToken: 'pub27dfa604d21952c62a0c260c5e499967',
    site: 'datadoghq.eu',
    service: 'cin-fd-surveys',
    forwardErrorsToLogs: false,
    env: getEnvironment(),
    version: DATADOG_VERSION,
    sessionSampleRate: 100
  })
}

const App = () => {
  initDatadogLogs()

  return (
    <Provider store={store}>
      <div className="App">
        {!isProd && !!preProdBranch && !!latestReleaseVersion && (
          <StagingInfoBanner
            branch={preProdBranch}
            version={latestReleaseVersion}
          />
        )}
        <ApolloProvider client={client}>
          <ThemeProvider>
            <StyledEngineProvider injectFirst>
              <Routes>
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/signup">
                  <Route index element={<SignupComponent />} />
                  <Route path=":iid" element={<SignupComponent />} />
                  <Route path=":auto-signup" element={<SignupComponent />} />
                </Route>
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordComponent />}
                />
                <Route path="/forgot-password-successful">
                  <Route index element={<ForgotPasswordSuccessful />} />
                  <Route path=":email" element={<ForgotPasswordSuccessful />} />
                </Route>
                <Route
                  path="/success-message/:email/:requestId"
                  element={<SignupSuccessful />}
                />
                <Route
                  path="/create-password/:requestId"
                  element={<CreatePassword />}
                />
                <Route path="/reset-password">
                  <Route index element={<ResetPasswordContainer />} />
                  <Route path=":rid" element={<ResetPasswordContainer />} />
                </Route>
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route
                  path="*"
                  element={
                    <RefreshTokenWrapper>
                      <Page />
                    </RefreshTokenWrapper>
                  }
                />
              </Routes>
            </StyledEngineProvider>
          </ThemeProvider>
        </ApolloProvider>
      </div>
    </Provider>
  )
}

export default App
