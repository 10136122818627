import orderBy from 'lodash/orderBy'
import { SearchResultEntry } from '../controls/Filters'
import {
  ChartEntry,
  EntryType,
  QuestionKind,
  QuestionType
} from '../data/model/chart'
import {
  FilterOption,
  ForkFilter,
  SearchableFilterOption,
  SelectedFilter
} from '../data/model/results'
import { chain } from './lodashChain'

export interface WeightingData {
  number?: number
  text: string
}

export const getDefaultFilterOptions = (
  chartEntries: SearchableFilterOption[]
): SearchResultEntry[] => {
  return chartEntries
    .filter(
      (chartEntry) =>
        (chartEntry.questionKind === QuestionKind.Audience &&
          chartEntry.isMultipleChoice === false) ||
        chartEntry.entryType === EntryType.ForkEntryType
    )
    .map((chartEntry) => {
      return { chartEntry }
    })
}

const getResponseOptions = (entry: SearchResultEntry) => {
  if (entry.isMatrix) {
    const row = entry.chartEntry.rows[entry.matrixRow || 0]
    return row.rowItems
      .map((rowItem) => {
        return {
          id: rowItem.id,
          position: rowItem.position,
          text: rowItem.text,
          completes: rowItem.completes
        }
      })
      .filter((ro) => ro.completes !== 0)
  }
  if (entry.chartEntry.entryType === EntryType.ForkEntryType) {
    return entry.chartEntry.rows.map((row) => {
      return {
        id: row.id,
        position: row.position,
        text: row.text,
        completes: row.rowItems[0].completes
      }
    })
  }
  return entry.chartEntry.rows
    .map((row) => {
      const responseOption = row.rowItems.find(
        (item) => item.text === 'Selected'
      )
      return {
        id: row.id,
        position: row.position,
        text: row.text,
        completes: responseOption?.completes
      }
    })
    .filter((responseOption) => responseOption.completes)
}

export const getFilterOptions: (
  filterSearchEntries: SearchResultEntry[]
) => FilterOption[] = (filterSearchEntries) => {
  return filterSearchEntries
    .sort((a, b) => a.chartEntry.entryPosition - b.chartEntry.entryPosition)
    .map((question) => {
      const id = question.isMatrix
        ? question.chartEntry.rows[question.matrixRow || 0].id
        : question.chartEntry.id
      const matrixTitleId = question.isMatrix
        ? question.chartEntry.id
        : undefined

      const text = question.isMatrix
        ? `${question.chartEntry.text} ${
            question.chartEntry.rows[question.matrixRow || 0].text
          }`
        : question.chartEntry.text

      return {
        id,
        matrixTitleId,
        text,
        position: question.chartEntry.contextPosition,
        samplesCollected: question.chartEntry.samplesCollected,
        samplesCollectedFiltered: question.chartEntry.samplesCollectedFiltered,
        responseOptions: getResponseOptions(question),
        questionKind: question.chartEntry.questionKind
      }
    })
}

export const transformForkDataIntoSearchableFilterOption = (
  forkFilters: ForkFilter[]
): SearchableFilterOption[] => {
  return forkFilters.map((forkFilter, index) => {
    return {
      id: forkFilter.forkId,
      text: forkFilter.forkName,
      entryPosition: index - 100, // this is because the entry position for forks is coming from the draftQuestionnaire so it will overlap with some question
      contextPosition: index + 1, // the contextPosition in the draftQuestionnaire is 0 based while the one in the summary is 1 based
      entryType: EntryType.ForkEntryType,
      questionType: QuestionType.none,
      questionKind: QuestionKind.None,
      isMultipleChoice: false,
      samplesWanted: 0, // forks don't show these numbers in the  UI so we don't need them
      samplesCollected: 0,
      samplesCollectedFiltered: 0,
      rows: forkFilter.branches
        .sort((a, b) => a.branchNumber - b.branchNumber)
        .map((branch, index) => {
          return {
            id: branch.branchNumber.toString(),
            text: branch.branchLabel,
            position: index,
            samplesCollected: 0,
            samplesCollectedFiltered: 0,
            weightedSamplesCollected: 0,
            weightedSamplesCollectedFiltered: 0,
            rowItems: [
              {
                id: forkFilter.forkId,
                text: branch.branchLabel,
                position: index,
                completes: 0
              }
            ]
          }
        })
    }
  })
}

export const constructSearchableFilterOptions = (
  allFilterOptions: SearchableFilterOption[]
): SearchableFilterOption[] => {
  return chain(allFilterOptions)
    .filter(
      (chartEntry) =>
        chartEntry.questionKind === QuestionKind.Audience ||
        chartEntry.questionType === QuestionType.basic ||
        chartEntry.questionType === QuestionType.matrix ||
        chartEntry.entryType === EntryType.ForkEntryType
    )
    .sortBy((chartEntry) => chartEntry.entryPosition)
    .value()
}

export const orderSelectedFilterOptions = (
  selectedFilterOptions: SearchResultEntry[]
): FilterOption[] => {
  return orderBy(
    getFilterOptions(selectedFilterOptions),
    'chartEntry.questionKind',
    'desc'
  )
}

export const getSelectedFilterCount = (
  selectedFilters: SelectedFilter[]
): number => {
  return selectedFilters
    .map(
      (selectedFilter) =>
        selectedFilter.branchNumbers?.length ||
        0 + (selectedFilter.responseOptionIds?.length || 0)
    )
    .reduce((acc, num) => acc + num, 0)
}

export const replaceCompletesOnWeightedData = (
  chartEntry: ChartEntry,
  showWeightedData: boolean
): ChartEntry => {
  if (!showWeightedData) {
    return chartEntry
  }

  return {
    ...chartEntry,
    samplesCollected:
      chartEntry.weightedSamplesCollected !== undefined
        ? chartEntry.weightedSamplesCollected
        : chartEntry.samplesCollected,
    samplesCollectedFiltered:
      chartEntry.weightedSamplesCollectedFiltered !== undefined
        ? chartEntry.weightedSamplesCollectedFiltered
        : chartEntry.samplesCollectedFiltered,
    rows: chartEntry.rows.map((row) => {
      return {
        ...row,
        samplesCollected:
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          row.weightedSamplesCollected !== undefined
            ? row.weightedSamplesCollected
            : row.samplesCollected,
        samplesCollectedFiltered:
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          row.weightedSamplesCollectedFiltered !== undefined
            ? row.weightedSamplesCollectedFiltered
            : row.samplesCollectedFiltered,
        rowItems: row.rowItems.map((rowItem) => {
          return {
            ...rowItem,
            completes:
              rowItem.weightedCompletes !== undefined
                ? rowItem.weightedCompletes
                : rowItem.completes
          }
        })
      }
    })
  }
}

export const transformWeightedQuestionsToTooltipMarkup = (
  weightingScheme?: WeightingData[]
): JSX.Element => {
  return (
    <>
      <b>Weighting on:</b> <br />
      {weightingScheme?.map((ws) => (
        <>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {ws?.number ? `A${ws.number} ${ws.text}` : ws.text} <br />
        </>
      ))}
    </>
  )
}

export const matrixHasDifferentRowCounts = (entry: ChartEntry): boolean => {
  if (entry.rows.length === 0) {
    return false
  }

  const firstRowCount = entry.rows[0].samplesCollectedFiltered

  return entry.rows.some(
    (row) => row.samplesCollectedFiltered !== firstRowCount
  )
}
